/* --------------------------------
Grid
https://getbootstrap.com/docs/4.2/layout/grid/
-------------------------------- */
$grid-columns:      12;
$grid-gutter-width: 2em;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1140px,
  // Max-width screen
  xxl: 1480px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px
);
