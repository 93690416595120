@import
"./src/main/scss/mixins/media-query",
"./src/main/scss/mixins/state-indicator",
"./src/main/scss/functions/find-color-invert",
'swiper/dist/css/swiper.min';

/*
The padding prevents the shadow from being cut off
*/
$slidePadding: 5em;

// Logowall
.swiper-outer {
  &.is--swiper-logowall {
    .swiper {
      &-slide {
        opacity: 1;
        @include mq(md) {
          margin: 0 2em;
        }
      }
    }
  }
}

// Quotes
.swiper {
  &-outer {
    position: relative;
    /*
    Remove grid gutter
    The animations would otherwise be cut off to the side!
    */
    margin: 0 calc(-1 * (#{$grid-gutter-width} / 2));
    @include mq(md) {
      margin: 0;
    }

    &:hover {
      @include mq(lg) {
        .swiper {
          &-button-prev:not(.swiper-button-disabled) {
            opacity: 1;
            transform: translateX(-50%);
          }

          &-button-next:not(.swiper-button-disabled) {
            opacity: 1;
            transform: translateX(50%);
          }
        }
      }
    }

    .swiper {
      &-container {
        padding: 0;
        @include mq(md) {
          padding: 0 $slidePadding;
        }
        // background: yellow;
        position: relative;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        list-style: none;
        z-index: 1;
        @include mq(xxl) {
          // das padding setzt sozusagen die max-width
          padding: 0 calc((100% - #{map-get($container-max-widths, 'xl')} + #{$slidePadding}) / 2);
          //max-width: map-get($container-max-widths, 'xl');
          //box-sizing: content-box;
          margin: 0 auto;
        }
      }

      &-wrapper {
        // background: green;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        margin: 0 auto;
      }

      &-slide {
        display: flex;
        flex: 1 0 auto;
        justify-content: center;
        align-items: stretch;
        height: auto;
        width: auto;
        max-width: 100%;
        position: relative;
        opacity: 0;
        transition: opacity .25s ease-in-out;

        &-inner {
          //background: red;
          min-width: 120px;
          max-width: var(--container-sm-max-widths);
          margin: 20px;
          display: flex;
          justify-content: center;
        }

        &-active {
          opacity: 1;
        }

        .is {
          &--max-width {
            max-width: 380px;
          }
        }
      }

      // Controls
      &-controls {
        display: flex;
        align-items: center;
        margin: 10px auto 0;
        // padding: 0 var(--space-sm);
        width: 100%;
        max-width: map-get($container-max-widths, 'xl');
        color: rgba(0,0,0,.5);
        font-size: var(--text-xs);
        font-weight: var(--type-weight-meta);

        .swiper {
          /*
          Pagination default
          */
          &-pagination {
            position: relative;
            width: auto;
            margin: 0 auto;
            line-height: 1;
            @include mq(lg) {
              font-size: var(--text-md);
            }

            &-bullet {
              margin: 0 var(--space-xxs);
              background: #000;
              outline: none;
              background: none;
              border: 2px solid #3c3c3c;
              width: 10px;
              height: 10px;

              &-active {
                background: var(--color-accent);
                border-color: var(--color-accent);
              }
            }

            &.is {
              &--white {
                .swiper-pagination {
                  &-bullet {
                    background: #fff;
                  }
                }
              }
            }
          }

          /*
          Buttons default
          */
          &-button-prev,
          &-button-next {
            position: relative;
            top: auto;
            bottom: 0;

            display: flex;
            justify-content: center;
            align-items: center;
            background: none;
            height: 36px;
            width: 36px;
            line-height: 36px;
            border-radius: 50%;
            margin: 0;
            outline: none;
            font-family: var(--font-icon);
            background: var(--color-accent);
            color: #fff;
            opacity: 1;
            transition:
              transform .6s cubic-bezier(0.175, 0.885, 0.32, 1.275),
              opacity .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            @include state-indicator();
            @include mq(md) {
              position: absolute;
              top: 50%;
              bottom: auto;
            }
            @include mq(xxl) {
              height: 48px;
              width: 48px;
              line-height: 48px;
            }

            &:before {
              display: inline-block;
            }

            &.swiper-button-disabled {
              opacity: 0;
            }
          }

          /*
          Button previous
          */
          &-button-prev {
            left: calc(#{$slidePadding} / 2);
            transform: translateX(-50%);
            @include mq(md) {
              left: $slidePadding;
            }
            @include mq(lg) {
              opacity: 0;
              transform: none;
            }
            @include mq(xxl) {
              left: calc((100% - #{map-get($container-max-widths, 'xl')}) / 2);
            }

            &:before {
              content: '\e95e';
              font-size: var(--text-xl);
            }
          }

          /*
          Button next
          */
          &-button-next {
            right: calc(#{$slidePadding} / 2);
            transform: translateX(50%);
            @include mq(md) {
              right: $slidePadding;
            }
            @include mq(lg) {
              opacity: 0;
              transform: none;
            }
            @include mq(xxl) {
              right: calc((100% - #{map-get($container-max-widths, 'xl')}) / 2);
            }

            &:before {
              content: '\e95f';
              font-size: var(--text-xl);
            }
          }
        }
      }
    }
  }
}
